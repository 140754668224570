import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { CREATE_A_DISCLOSER } from '@/graphql/disclosure/mutations';
import { CreateDiscloserListMutation } from '@/lib/discloser/__generated__/graphql';
import { useStore as authorityTypeStore } from '@/store/authorityType';
import { useStore } from '@/store/discloser';
import { disclosureClient } from '@/utils/apollo-client';
import { formatDate } from '@/utils/date';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useMutation } from '@apollo/client';
import { AccessTimeOutlined, CachedOutlined, CheckOutlined } from '@mui/icons-material';
import { Button, Chip, Stack, Typography, styled } from '@mui/material';

type ActionName =
  | 'create_target_list'
  | 'add_organisations_to_list'
  | 'submit_list'
  | 'create_a_tracking_portfolio'
  | 'analyse_performance_and_view_data'
  | 'provide_feedback_to_disclosers'
  | 'view_your_suppliers_scores'
  | 'select_a_sample'
  | 'chase_non_responders'
  | 'engage_organisations';

const ButtonWrapper = styled(Button)(({ theme }) => ({
  gap: 2,
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.text.primary,
  alignItems: 'center',
  padding: 0,
}));

type Props = {
  discloserListId?: string;
  index: number;
  name: string;
  status: string;
  deadline?: string;
};
export const ActionItem = ({ discloserListId, index, name, status, deadline }: Props) => {
  const router = useRouter();
  const { authorityTypeId } = authorityTypeStore();
  const {
    actions: { setResponseStatusFilter },
  } = useStore();
  const { t } = useTranslation('common', { keyPrefix: 'common.suggestedKeyActionTile' });

  let icon;
  switch (status) {
    case 'todo': {
      const stepNumber = index + 1;
      const formattedStepNumber = stepNumber.toString().padStart(2, '0');
      icon = (
        <Stack
          height={32}
          width={32}
          borderRadius="24px"
          justifyContent="center"
          alignItems="center"
          border={theme => `1px dashed ${theme.palette.text.secondary}`}
        >
          <Typography variant="caption">{formattedStepNumber}</Typography>
        </Stack>
      );
      break;
    }
    case 'progressing': {
      icon = (
        <Stack
          height={32}
          width={32}
          borderRadius="24px"
          justifyContent="center"
          alignItems="center"
          bgcolor="#E5F3FF" // TODO: [wnguyen] fix when design system is finalised
        >
          <CachedOutlined color="info" />
        </Stack>
      );
      break;
    }
    case 'done': {
      icon = (
        <Stack
          height={32}
          width={32}
          borderRadius="24px"
          justifyContent="center"
          alignItems="center"
          bgcolor="#E2F9D0" // TODO: [wnguyen] fix when design system is finalised
        >
          <CheckOutlined color="success" />
        </Stack>
      );
      break;
    }
    default:
      throw new Error(`Unknown todo status: ${status}`);
  }

  const [
    createDiscloser,
  ] = useMutation<CreateDiscloserListMutation>(CREATE_A_DISCLOSER, {
    variables: {
      createDiscloserListInput: { authorityTypeId },
    },
    onError: onApolloError,
    client: disclosureClient,
  });

  const titleKey = `${name}.title` as unknown as TemplateStringsArray;
  const descKey = `${name}.description` as unknown as TemplateStringsArray;

  const getActionLink = (name: ActionName) => {
    switch (name) {
      case 'create_target_list': {
        const handleCreateDiscloser = async () => {
          const res = await createDiscloser();
          router.push({
            pathname: Routes.Authority.DiscloserList,
            query: {
              discloserListId: res.data?.createDiscloserList.discloserListId,
            },
          });
        };

        return handleCreateDiscloser();
      }
      case 'analyse_performance_and_view_data':
      case 'add_organisations_to_list':
      case 'submit_list': {
        return router.push({
          pathname: Routes.Authority.DiscloserList,
          query: { discloserListId },
        });
      }
      case 'create_a_tracking_portfolio': {
        return router.push({
          pathname: Routes.Authority.TrackingPortfolios.Create,
          query: { discloserListId },
        });
      }
      case 'select_a_sample': {
        return router.push({
          pathname: Routes.Authority.Root,
          query: {
            tab: 'samples',
          },
        });
      }
      case 'engage_organisations': {
        return router.push(Routes.Authority.Root);
      }
      case 'chase_non_responders': {
        const handleNonResponders = async () => {
          setResponseStatusFilter('No Response');
          router.push({
            pathname: Routes.Authority.DiscloserList,
            query: {
              discloserListId,
              filter: 'No Response',
            },
          });
        };
        return handleNonResponders();
      }
      case 'provide_feedback_to_disclosers': {
        break; // TODO: this functionality will be developed POST MVP and will be linked then
      }
      case 'view_your_suppliers_scores': {
        break; // TODO: this functionality will be developed POST MVP and will be linked then
      }
    }
  };

  return (
    <ButtonWrapper onClick={() => getActionLink(name as ActionName)}>
      <Stack
        direction="row"
        gap={2}
        width="100%"
        alignItems="center"
      >
        {icon}
        <Stack alignItems="flex-start">
          <Typography variant="body2">
            <b>{t(titleKey)}</b>
          </Typography>
          <Typography variant="caption">{t(descKey)}</Typography>
        </Stack>
      </Stack>
      {deadline && status !== 'done' && (
        <Chip
          icon={<AccessTimeOutlined fontSize="small" />}
          label={formatDate(deadline, 'MM/dd/yyyy')}
        />
      )}
    </ButtonWrapper>
  );
};
