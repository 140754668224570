// mui
// image
import { ResourceKey } from 'i18next';
import Image from 'next/image';
// i18n
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, MobileStepper, Stack, Typography } from '@mui/material';

export interface PopupStepTutorialProps {
  totalStepNumber: number;
  imageSrc: string;
  title: ResourceKey;
  desc: ResourceKey;
  activeStepIndex: number;
  isActive: boolean;
  onBack: () => void;
  onNext: () => void;
}

export default function PopupStepTutorial(props: PopupStepTutorialProps) {
  const { totalStepNumber, imageSrc, title, desc, activeStepIndex, isActive, onBack, onNext } = props;
  const { t } = useTranslation('common', {
    keyPrefix: 'common.firstTimeSignInPopup',
  });

  return (
    <Box sx={{ display: isActive ? 'block' : 'none' }}>
      <Image
        src={imageSrc}
        alt="image"
        width={0}
        height={0}
        sizes="100w"
        style={{ width: '100%', height: 'auto', marginBottom: '24px' }}
        priority
      />
      <Stack
        direction="column"
        alignItems="left"
        spacing={1}
      >
        <Typography variant="h5">{t(title)}</Typography>

        <Typography
          variant="body1"
          sx={{ minHeight: '48px' }}
        >
          {t(desc)}
        </Typography>
      </Stack>

      <MobileStepper
        variant="dots"
        steps={totalStepNumber}
        position="static"
        activeStep={activeStepIndex}
        sx={{
          flexGrow: 1,
          background: 'transparent',
          p: 0,
          pt: theme => theme.spacing(4),
          pb: theme => theme.spacing(4),
        }}
        nextButton={
          <Button
            size="large"
            variant="contained"
            onClick={onNext}
            color="primary"
            startIcon={<ChevronRight />}
            data-test-id="continue-tutorial-button"
          >
            {activeStepIndex === totalStepNumber - 1 ? t('finishTutorialBtnLabel') : t('nextBtnLabel')}
          </Button>
        }
        backButton={
          <Button
            size="large"
            variant="text"
            onClick={onBack}
            color="primary"
            startIcon={<ChevronLeft />}
            data-test-id="skip-tutorial-button"
          >
            {t('backBtnLabel')}
          </Button>
        }
      />
    </Box>
  );
}
