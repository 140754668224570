import { CountryGraphsCard } from './CountryGraphsCard';
import { PendingEmptyListCard } from './PendingEmptyListCard';

type Props = {
  disclosureCycleId?: string | null;
  discloserListId?: string | null;
  organisationCount?: number;
};
export const PendingCard = ({ disclosureCycleId, discloserListId, organisationCount = 0 }: Props) => {
  return organisationCount == 0 ? (
    <PendingEmptyListCard discloserListId={discloserListId} />
  ) : (
    <CountryGraphsCard disclosureCycleId={disclosureCycleId} />
  );
};
