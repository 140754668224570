import { useEffect } from 'react';
import Loading from '@/components/common/loading/Loading';
import { AuthorityTagTypes } from '@/components/home/Authority/types';
import {
  GET_ACTIVE_CYCLE,
  GET_LIST_FOR_CYCLE,
  GET_MY_ORG_DETAILS,
  GET_NEXT_DISCLOSURE_CYCLE,
  GET_RECENT_DISCLOSER_LISTS,
  GET_RESPONSE_RATE_STATS,
} from '@/graphql/disclosure/queries';
import { useIsOnAdmin } from '@/hooks/useUserAccess';
import {
  GetActiveDisclosureCycleQuery,
  GetAllRecentDiscloserListsQuery,
  GetDiscloserListForCycleQuery,
  GetMyOrganisationDetailsQuery,
  GetNextDisclosureCycleQuery,
  GetResponseRateStatsQuery,
} from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { AUTHORITY_TYPE_PRIORITY_LIST } from '@/utils/priotityOrderAuthorityTypes';
import { useQuery } from '@apollo/client';
import { PageData } from './types';
import { getPageData } from './utils';
import { CurrentListView, EmptyListView, NoListView, PreviousListView } from './views';

export const AuthorityHomePage = () => {
  const { authorityTypeId, setAuthorityTypeId } = useStore();
  const isOnAdmin = useIsOnAdmin();

  const { data: orgData, loading } = useQuery<GetMyOrganisationDetailsQuery>(GET_MY_ORG_DETAILS, {
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
    skip: isOnAdmin,
  });

  const { data: activeCycleData, loading: isLoadingCycleData } = useQuery<GetActiveDisclosureCycleQuery>(
    GET_ACTIVE_CYCLE,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      variables: { authorityTypeId },
      skip: !authorityTypeId || isOnAdmin,
    },
  );

  const { data: activeDiscloserListData, loading: loadingActiveDiscloserListData } =
    useQuery<GetDiscloserListForCycleQuery>(GET_LIST_FOR_CYCLE, {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip: !authorityTypeId || !activeCycleData?.getActiveDisclosureCycle || isLoadingCycleData || isOnAdmin,
      variables: {
        authorityTypeId: authorityTypeId,
        disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      },
    });

  const { data: allDiscloserListData, loading: loadingAllDiscloserList } = useQuery<GetAllRecentDiscloserListsQuery>(
    GET_RECENT_DISCLOSER_LISTS,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip: !authorityTypeId || !!activeCycleData?.getActiveDisclosureCycle || isOnAdmin,
      variables: { authorityTypeId },
    },
  );

  const { data: nextDisclosureCycleData, loading: isLoadingNextDisclosureCycle } =
    useQuery<GetNextDisclosureCycleQuery>(GET_NEXT_DISCLOSURE_CYCLE, {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip:
        !authorityTypeId ||
        isOnAdmin ||
        (activeCycleData?.getActiveDisclosureCycle !== null &&
          allDiscloserListData?.getAllAuthorityDiscloserLists.length !== 0),
      variables: { authorityTypeId },
    });

  const { data: nextDiscloserListData, loading: loadingNextDiscloserListData } =
    useQuery<GetDiscloserListForCycleQuery>(GET_LIST_FOR_CYCLE, {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip:
        !authorityTypeId ||
        !nextDisclosureCycleData?.getNextDisclosureCycle ||
        isLoadingNextDisclosureCycle ||
        isOnAdmin,
      variables: {
        authorityTypeId: authorityTypeId,
        disclosureCycleId: nextDisclosureCycleData?.getNextDisclosureCycle?.disclosureCycleId,
      },
    });

  const { data: responseRateData } = useQuery<GetResponseRateStatsQuery>(GET_RESPONSE_RATE_STATS, {
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
    skip:
      !authorityTypeId ||
      activeCycleData?.getActiveDisclosureCycle === null ||
      activeDiscloserListData?.getDiscloserListForCycle?.status !== 'active' ||
      isOnAdmin,
    variables: {
      authorityTypeId,
      disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
    },
  });

  const authorityTypes = orgData?.getMyOrganisationDetails?.organisationAuthorityTypes?.map(authorityType => ({
    id: authorityType.authorityType.authorityTypeId,
    name: authorityType.authorityType.name,
  }));

  useEffect(() => {
    const setAuthorityTypeIdOnce = () => {
      if (!authorityTypeId && orgData && authorityTypes) {
        for (const priorityAuthorityType of AUTHORITY_TYPE_PRIORITY_LIST) {
          const authorityType = authorityTypes.find(authorityType => authorityType.name === priorityAuthorityType);

          if (authorityType) {
            setAuthorityTypeId(authorityType.id);
            return;
          }
        }
      }
    };
    setAuthorityTypeIdOnce();
  });

  if (
    loading ||
    isLoadingCycleData ||
    loadingActiveDiscloserListData ||
    loadingAllDiscloserList ||
    isLoadingNextDisclosureCycle ||
    loadingNextDiscloserListData
  ) {
    return <Loading />;
  }

  const submittedResponseRate = responseRateData?.getResponseRateStats?.find(item => item?.status === 'Submitted')
    ?.authorityOrgsPercentage;

  const pageData: PageData = getPageData(
    activeCycleData,
    allDiscloserListData,
    nextDisclosureCycleData,
    activeDiscloserListData,
    nextDiscloserListData,
    submittedResponseRate,
  );

  switch (pageData._tag) {
    case AuthorityTagTypes.NO_LIST: {
      return <NoListView data={pageData} />;
    }
    case AuthorityTagTypes.EMPTY_LIST: {
      return <EmptyListView data={pageData} />;
    }
    case AuthorityTagTypes.PREVIOUS_LIST: {
      return <PreviousListView data={pageData} />;
    }
    case AuthorityTagTypes.CURRENT_LIST: {
      return <CurrentListView data={pageData} />;
    }
    case AuthorityTagTypes.UNKNOWN_STATE: {
      return null;
    }
  }
};
