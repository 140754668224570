import { useTranslation } from 'next-i18next';
import { IconTitle } from '@/components/discloser/Dashboard/IconTitle';
import { GET_TODOS } from '@/graphql/disclosure/queries';
import { GetTodosQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { FactCheckOutlined } from '@mui/icons-material';
import { Alert, Skeleton, Stack } from '@mui/material';
import { ActionItem } from './ActionItem';

type Props = { discloserListId?: string };
export const SuggestedKeyActions = ({ discloserListId }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.suggestedKeyActionTile' });

  const { authorityTypeId } = useStore();

  const { data, loading } = useQuery<GetTodosQuery>(GET_TODOS, {
    client: disclosureClient,
    variables: { authorityTypeId },
    onError: onApolloError,
  });

  if (loading) return <Skeleton aria-label={t('loading')} />;

  return (
    <Stack
      bgcolor="background.default"
      p={2}
      height="100%"
      alignItems="flex-start"
      minHeight={228}
    >
      <IconTitle
        icon={<FactCheckOutlined />}
        label={t('title')}
      />
      {!data?.getTodos ? (
        <Alert
          severity="warning"
          style={{ marginTop: '1rem' }}
        >
          {t('noData')}
        </Alert>
      ) : (
        <Stack
          mt={2}
          width="100%"
          bgcolor={theme => theme.palette.background.default}
          p={2}
          gap={2}
        >
          {data.getTodos.map(({ name, status, deadline }, index) => (
            <ActionItem
              key={name}
              index={index}
              name={name}
              status={status}
              deadline={deadline}
              discloserListId={discloserListId}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
