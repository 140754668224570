// mui
// i18n
import { Trans, useTranslation } from 'react-i18next';
import { commonConfig } from '@config';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, DialogActions, Link, Stack, Typography, alpha } from '@mui/material';

export interface PopupStepBecomeSubmissionLeadProps {
  goToHomepage: () => void;
  goToTeamsPage: () => void;
}

export default function PopupStepBecomeSubmissionLead(props: PopupStepBecomeSubmissionLeadProps) {
  const { goToHomepage, goToTeamsPage } = props;
  const { t } = useTranslation('common', {
    keyPrefix: 'common.firstTimeSignInPopup.popups.becomeSubmissionLead',
  });

  return (
    <Box>
      <Stack
        direction="column"
        alignItems="left"
        spacing={2}
        pb={4}
      >
        <Typography variant="h4">{t('title')}</Typography>

        <Typography
          variant="body1"
          color="secondary"
          sx={{ whiteSpace: 'pre-line' }}
        >
          <Trans
            i18nKey="common.firstTimeSignInPopup.popups.becomeSubmissionLead.desc"
            components={[
              <Link
                target="_blank"
                href={commonConfig.termsOfDisclosureLink}
                key="termsOfDisclosure"
                color="inherit"
                underline="always"
                sx={{ cursor: 'pointer' }}
              />,
            ]}
          />
        </Typography>

        <Stack
          direction="row"
          alignItems="left"
          spacing={1.5}
          p={2}
          sx={{
            display: 'flex',
            background: theme => alpha(theme.palette.info.main, 0.08),
            borderRadius: '4px',
            p: 2,
          }}
        >
          <InfoOutlined color="info" />
          <Typography
            variant="body2"
            color="info.dark"
          >
            {t('extraDesc')}
          </Typography>
        </Stack>
      </Stack>

      <DialogActions>
        <Button
          size="large"
          variant="outlined"
          onClick={goToHomepage}
          color="primary"
          data-testid="go-with-contributor-button"
        >
          {t('contributorBtnLabel')}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={goToTeamsPage}
          color="primary"
          data-testid="go-with-submission-lead-button"
        >
          {t('leadBtnLabel')}
        </Button>
      </DialogActions>
    </Box>
  );
}
