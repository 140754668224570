import moment from 'moment';
import { AuthorityTagTypes } from '@/components/home/Authority/types';
import {
  GetActiveDisclosureCycleQuery,
  GetAllRecentDiscloserListsQuery,
  GetDiscloserListForCycleQuery,
  GetNextDisclosureCycleQuery,
} from '@/lib/discloser/__generated__/graphql';
import { PageData } from './types';

export const getPageData = (
  activeCycleData?: GetActiveDisclosureCycleQuery,
  allDiscloserListData?: GetAllRecentDiscloserListsQuery,
  nextDisclosureCycleData?: GetNextDisclosureCycleQuery,
  activeDiscloserListData?: GetDiscloserListForCycleQuery,
  nextDiscloserListData?: GetDiscloserListForCycleQuery,
  submittedResponseRate?: number | null,
): PageData => {
  if (
    activeCycleData?.getActiveDisclosureCycle === null &&
    !allDiscloserListData?.getAllAuthorityDiscloserLists.length &&
    nextDisclosureCycleData?.getNextDisclosureCycle &&
    calculateDaysDifference(nextDisclosureCycleData.getNextDisclosureCycle.startDate) >
      nextDisclosureCycleData.getNextDisclosureCycle.cycleMargin
  ) {
    return {
      _tag: AuthorityTagTypes.NO_LIST,
      nextListYear: nextDisclosureCycleData.getNextDisclosureCycle.year,
      nextListDeadline: nextDisclosureCycleData.getNextDisclosureCycle.deadlineToDisclose,
      nextListSubmitDueDate: nextDisclosureCycleData.getNextDisclosureCycle.submitDueDate,
    };
  }

  if (
    (activeCycleData?.getActiveDisclosureCycle || nextDisclosureCycleData?.getNextDisclosureCycle) &&
    !activeDiscloserListData?.getDiscloserListForCycle &&
    !nextDiscloserListData?.getDiscloserListForCycle
  ) {
    if (activeCycleData?.getActiveDisclosureCycle) {
      return {
        _tag: AuthorityTagTypes.EMPTY_LIST,
        currentYear: activeCycleData.getActiveDisclosureCycle.year,
        discloserCycleName: activeCycleData.getActiveDisclosureCycle.name,
      };
    }

    if (nextDisclosureCycleData?.getNextDisclosureCycle) {
      return {
        _tag: AuthorityTagTypes.EMPTY_LIST,
        currentYear: nextDisclosureCycleData?.getNextDisclosureCycle?.year,
        discloserCycleName: nextDisclosureCycleData?.getNextDisclosureCycle?.name,
      };
    }
  }

  if (
    !activeCycleData?.getActiveDisclosureCycle &&
    allDiscloserListData?.getAllAuthorityDiscloserLists.length &&
    !nextDisclosureCycleData?.getNextDisclosureCycle
  ) {
    const [
      recentDiscloserList,
    ] = allDiscloserListData.getAllAuthorityDiscloserLists;

    return {
      _tag: AuthorityTagTypes.PREVIOUS_LIST,
      prevListYear: recentDiscloserList.disclosureCycle.year,
      prevListDeadline: recentDiscloserList.disclosureCycle.deadlineToDisclose,
      prevListSubmitDueDate: recentDiscloserList.disclosureCycle.submitDueDate,
      invitedOrganisationsCount: recentDiscloserList.invitedOrganisationsCount,
      submittedResponseRate: recentDiscloserList.responseRate,
      status: recentDiscloserList.status,
      totalOrganisationCount: recentDiscloserList.totalOrganisationCount,
      disclosureCycleId: recentDiscloserList.disclosureCycle.disclosureCycleId,
      discloserListId: recentDiscloserList.discloserListId,
    };
  }

  if (activeCycleData?.getActiveDisclosureCycle && activeDiscloserListData?.getDiscloserListForCycle?.status) {
    return {
      _tag: AuthorityTagTypes.CURRENT_LIST,
      currentListDeadline: activeCycleData.getActiveDisclosureCycle.deadlineToDisclose,
      currentListSubmitDueDate: activeCycleData.getActiveDisclosureCycle.submitDueDate,
      currentListYear: activeCycleData.getActiveDisclosureCycle.year,
      invitedOrganisationsCount: activeDiscloserListData.getDiscloserListForCycle.invitedOrganisationsCount,
      submittedResponseRate: submittedResponseRate,
      status: activeDiscloserListData.getDiscloserListForCycle.status,
      totalOrganisationCount: activeDiscloserListData.getDiscloserListForCycle.totalOrganisationCount,
      disclosureCycleId: activeCycleData.getActiveDisclosureCycle.disclosureCycleId,
      discloserListId: activeDiscloserListData.getDiscloserListForCycle.discloserListId,
    };
  }

  if (nextDisclosureCycleData?.getNextDisclosureCycle && nextDiscloserListData?.getDiscloserListForCycle?.status) {
    return {
      _tag: AuthorityTagTypes.CURRENT_LIST,
      currentListDeadline: nextDisclosureCycleData.getNextDisclosureCycle.deadlineToDisclose,
      currentListSubmitDueDate: nextDisclosureCycleData.getNextDisclosureCycle.submitDueDate,
      currentListYear: nextDisclosureCycleData.getNextDisclosureCycle.year,
      invitedOrganisationsCount: nextDiscloserListData.getDiscloserListForCycle.invitedOrganisationsCount,
      submittedResponseRate: submittedResponseRate,
      status: nextDiscloserListData.getDiscloserListForCycle.status,
      totalOrganisationCount: nextDiscloserListData.getDiscloserListForCycle.totalOrganisationCount,
      disclosureCycleId: nextDisclosureCycleData.getNextDisclosureCycle.disclosureCycleId,
      discloserListId: nextDiscloserListData.getDiscloserListForCycle.discloserListId,
    };
  }

  return { _tag: AuthorityTagTypes.UNKNOWN_STATE };
};

function calculateDaysDifference(startDate: Date, endDate?: Date) {
  const end = endDate || new Date();

  const diffInDays = Math.ceil(moment(startDate).diff(moment(end), 'days', true));

  if (Math.abs(diffInDays) === 0) {
    return 0;
  }

  return diffInDays;
}
