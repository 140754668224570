import { useTranslation } from 'next-i18next';
import router from 'next/router';
import { useState } from 'react';
import Loading from '@/components/common/loading/Loading';
import {
  GET_RESPONSE_RATE_STATS,
  GET_SAMPLES_BY_AUTHORITY,
  GET_SAMPLE_RESPONSE_RATE,
} from '@/graphql/disclosure/queries';
import {
  GetCycleSamplesByAuthorityTypeIdQuery,
  GetResponseRateStatsQuery,
  GetSampleResponseRateQuery,
} from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { AssignmentReturnOutlined, EditOffOutlined, EditOutlined, SendOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { CardHeader } from './CardHeader';
import { CardLayout } from './CardLayout';
import { ProgressRow } from './ProgressRow';

type Props = { disclosureCycleId?: string | null };
export const ActiveOrClosedCard = ({ disclosureCycleId }: Props) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.authority.distributionAndResponseRateCard',
  });
  const { authorityTypeId } = useStore();
  const isCMSAuthorityType = authorityTypeId === AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY();
  const { data: responseRateData, loading: loadingResponseRate } = useQuery<GetResponseRateStatsQuery>(
    GET_RESPONSE_RATE_STATS,
    {
      skip: !disclosureCycleId || isCMSAuthorityType,
      fetchPolicy: 'network-only',
      client: disclosureClient,
      variables: {
        authorityTypeId,
        disclosureCycleId,
      },
      onError: onApolloError,
    },
  );

  // If authority is Capital Markets Signatory, we will display a different sample data instead of discloser list data
  // Hence the following queries with skip variable included
  const [
    sampleName,
    setSampleName,
  ] = useState<undefined | string>(undefined);
  const [
    trackingPortfolioId,
    setTrackingPortfolioId,
  ] = useState<null | string>(null);
  const [
    samplesCount,
    setSamplesCount,
  ] = useState<number | null>(null);

  const { loading: loadingAllSamples } = useQuery<GetCycleSamplesByAuthorityTypeIdQuery>(GET_SAMPLES_BY_AUTHORITY, {
    variables: { authorityType: authorityTypeId },
    client: disclosureClient,
    skip: !isCMSAuthorityType,
    fetchPolicy: 'network-only',
    onError: onApolloError,
    onCompleted: data => {
      setSamplesCount(data?.getCycleSamplesByAuthorityTypeId.count);
      const samples = data?.getCycleSamplesByAuthorityTypeId.data;
      if (samples && samples.length > 0) {
        const primarySample = samples.find(sample => sample.name.toLowerCase().includes('primary'));
        if (primarySample) {
          setTrackingPortfolioId(primarySample.trackingPortfolioId);
          setSampleName(primarySample.name);
        } else {
          setTrackingPortfolioId(samples[0].trackingPortfolioId);
          setSampleName(samples[0].name);
        }
      }
    },
  });

  const [
    sampleResponseRate,
    setSampleResponseRate,
  ] = useState<null | GetSampleResponseRateQuery>(null);

  const { loading: loadingSampleResponseRate } = useQuery<GetSampleResponseRateQuery>(GET_SAMPLE_RESPONSE_RATE, {
    variables: {
      trackingPortfolioId: trackingPortfolioId,
    },
    onCompleted: data => {
      setSampleResponseRate(data);
    },
    fetchPolicy: 'network-only',
    client: disclosureClient,
    skip: !isCMSAuthorityType || samplesCount === 0 || !trackingPortfolioId,
  });

  const findStatByStatus = (status: string) => {
    if (isCMSAuthorityType) {
      const stat = sampleResponseRate?.getSampleResponseRate?.find(item => item && item.status === status);
      return stat?.percentage ?? 0;
    } else {
      const stat = responseRateData?.getResponseRateStats?.find(item => item && item.status === status);
      return stat?.authorityOrgsPercentage || 0;
    }
  };

  const handleClickButton = async () => {
    await router.push({
      pathname: Routes.Authority.Root,
    });
  };

  if (loadingResponseRate || loadingAllSamples || loadingSampleResponseRate) {
    return (
      <Loading
        ariaLabel={t('loading')}
        isContained
      />
    );
  }

  if (!disclosureCycleId) return null;

  return (
    <CardLayout
      header={
        <CardHeader
          status="active"
          handleClickAddOrgsButton={handleClickButton}
          sampleName={sampleName}
        />
      }
    >
      {/* Keep strict equality operator as count is initialized to null */}
      {samplesCount === 0 ? (
        <Stack
          flexGrow={1}
          justifyContent="center"
        >
          <Typography
            variant="subtitle1"
            align="center"
          >
            {t('noSamples')}
          </Typography>
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="100%"
          bgcolor={theme => theme.palette.background.default}
          gap={2}
          p={2}
        >
          <ProgressRow
            label={t('noResponse')}
            percentage={findStatByStatus('No Response')}
            icon={<EditOffOutlined />}
          />
          <ProgressRow
            label={t('activated')}
            percentage={findStatByStatus('Activated')}
            icon={<EditOutlined />}
          />
          <ProgressRow
            label={t('amended')}
            percentage={findStatByStatus('Amended')}
            icon={<AssignmentReturnOutlined />}
          />
          <ProgressRow
            label={t('submitted')}
            percentage={findStatByStatus('Submitted')}
            icon={<SendOutlined />}
          />
        </Stack>
      )}
    </CardLayout>
  );
};
