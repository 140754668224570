import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { AddOrganisationsButton } from '../AddOrganisationsButton';
import { CardHeader } from './CardHeader';
import { CardLayout } from './CardLayout';

type Props = { discloserListId?: string | null };
export const PendingEmptyListCard = ({ discloserListId }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.authority.distributionAndResponseRateCard' });
  if (!discloserListId) return null;

  return (
    <CardLayout header={<CardHeader />}>
      <Typography variant="subtitle1">{t('noOrgAdded')}</Typography>
      <AddOrganisationsButton discloserListId={discloserListId} />
    </CardLayout>
  );
};
