import { useStore as useAuthorityStore } from '@/store/authorityType';
import { useStore } from '@/store/discloser';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
import { ListAltOutlined } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { ListDates } from './ListDates';
import { ListDetails } from './ListDetails';
import { SharedBox } from './SharedBox';
import { KPICardProps } from './types';

export const KPICard = ({ data }: KPICardProps) => {
  const {
    actions: { setResponseStatusFilter },
  } = useStore();

  const theme = useTheme();
  const { authorityTypeId } = useAuthorityStore();

  if (authorityTypeId === AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY()) return null;
  switch (data._tag) {
    case 'List': {
      const {
        status,
        organisationCount,
        submittedResponseRate,
        discloserListId,
        year,
        submitDueDate,
        deadlineToDisclose,
      } = data;

      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgcolor={theme => theme.palette.background.default}
          p={2}
          height="100%"
          gap={2}
          overflow="scroll"
        >
          <ListDetails
            status={status}
            organisationCount={organisationCount}
            submittedResponseRate={submittedResponseRate}
            setResponseStatusFilter={setResponseStatusFilter}
            discloserListId={discloserListId}
          />
          <ListDates
            year={year}
            submitDueDate={submitDueDate}
            deadlineToDisclose={deadlineToDisclose}
          />
        </Box>
      );
    }
    case 'NoList': {
      const { year, submitDueDate, deadlineToDisclose } = data;
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgcolor={theme => theme.palette.background.default}
          p={2}
        >
          <SharedBox
            IconComponent={<ListAltOutlined />}
            label="List Status"
            description="No list"
            iconColor={theme.palette.text.primary}
            circleBackgroundColor="transparent"
          />
          <ListDates
            year={year}
            submitDueDate={submitDueDate}
            deadlineToDisclose={deadlineToDisclose}
          />
        </Box>
      );
    }
  }
};
