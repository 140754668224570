import { ActiveOrClosedCard } from './ActiveOrClosedCard';
import { PendingCard } from './PendingCard';

type Props = {
  status: string;
  organisationCount: number;
  disclosureCycleId: string;
  discloserListId: string;
};
export const DistributionAndResponseRateCard = ({
  status,
  disclosureCycleId,
  discloserListId,
  organisationCount,
}: Props) => {
  switch (status) {
    case 'pending': {
      return (
        <PendingCard
          disclosureCycleId={disclosureCycleId}
          organisationCount={organisationCount}
          discloserListId={discloserListId}
        />
      );
    }
    case 'active':
    case 'closed': {
      return <ActiveOrClosedCard disclosureCycleId={disclosureCycleId} />;
    }
    default: {
      throw new Error('Unknown disclosure list status');
    }
  }
};
