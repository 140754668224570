// mui
// i18n
import { useTranslation } from 'react-i18next';
// config
import { commonConfig } from '@config';
import { Box, Button, DialogActions, Stack, Typography } from '@mui/material';

export interface FirstSignInPopUpProps {
  onSkip: () => void;
  onNext: () => void;
  userName?: string;
}

export default function WelcomeScreen(props: FirstSignInPopUpProps) {
  const { onSkip, onNext, userName } = props;
  const { t } = useTranslation('common', {
    keyPrefix: 'common.firstTimeSignInPopup',
  });

  return (
    <Box>
      <Box
        sx={{
          overflow: 'hidden',
          paddingBottom: '56.25%',
          position: 'relative',
          height: 0,
          marginBottom: '24px',
        }}
      >
        <iframe
          style={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
          }}
          src={commonConfig.welcomeYoutubeVideoEmbedLink}
          title="CDP Youtube video"
          allow="accelerometer; autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Box>

      <Stack
        direction="column"
        alignItems="left"
        spacing={1}
        sx={{ pb: 4 }}
      >
        <Typography variant="h4">{t('popups.welcomeScreen.title', { firstName: userName })}</Typography>

        <Typography
          variant="subtitle2"
          color="text.secondary"
        >
          {t('popups.welcomeScreen.desc')}
        </Typography>
      </Stack>

      <DialogActions>
        <Button
          size="large"
          variant="text"
          onClick={onSkip}
          color="primary"
          data-testid="skip-tutorial-button"
        >
          {t('skipBtnLabel')}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={onNext}
          color="primary"
          data-testid="continue-tutorial-button"
        >
          {t('continueBtnLabel')}
        </Button>
      </DialogActions>
    </Box>
  );
}
