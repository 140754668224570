import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthorityTagTypes } from '@/components/home/Authority/types';
import { formatDate } from '@/utils/date';
import { Alert, AlertTitle, Typography } from '@mui/material';

type Props = { viewType: Exclude<AuthorityTagTypes, AuthorityTagTypes.PREVIOUS_LIST> };

export const AlertCantSubmitList = ({ viewType }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: `common.authority.homepage.alertCantSubmitList.${viewType}` });

  const [
    alertOpen,
    setAlertOpen,
  ] = useState(true);

  const targetDate =
    process.env.NEXT_PUBLIC_DISCLOSER_LIST_OPENING_DATE &&
    new Date(process.env.NEXT_PUBLIC_DISCLOSER_LIST_OPENING_DATE);
  const isListSubmissionDisabled = targetDate && new Date() < targetDate;
  const targetDateStr = targetDate && formatDate(targetDate, 'MM/dd/yyyy');

  if (!alertOpen || !isListSubmissionDisabled) return null;

  return (
    <Alert
      severity="info"
      sx={{ mb: 2 }}
      onClose={() => setAlertOpen(false)}
      data-testid={`alert-cant-submit-${viewType}`}
    >
      <AlertTitle data-testid="alert-cant-submit-title">{t('title')}</AlertTitle>
      <Typography
        variant="body1"
        sx={{ mb: 2 }}
      >
        <Trans
          i18nKey={`common.authority.homepage.alertCantSubmitList.${viewType}.message`}
          values={{ openingDate: targetDateStr }}
        />
      </Typography>
    </Alert>
  );
};
