import { useTranslation } from 'next-i18next';
import router from 'next/router';
import { Routes } from '@/utils/urls';
import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';

type AddOrganisationsButtonProps = {
  discloserListId: string | undefined;
  variant?: 'contained' | 'outlined';
};
export const AddOrganisationsButton = ({ discloserListId, variant = 'outlined' }: AddOrganisationsButtonProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.authority.distributionAndResponseRateCard' });

  const handleClickButton = () => {
    router.push({
      pathname: Routes.Authority.DiscloserList,
      query: { discloserListId },
    });
  };

  return (
    <Button
      variant={variant}
      onClick={handleClickButton}
      startIcon={<Add />}
      sx={{ width: 'fit-content' }}
    >
      {t('addOrganisationCta')}
    </Button>
  );
};
