// types
import { useRouter } from 'next/router';
// react
import { useState } from 'react';
import PopupStepBecomeSubmissionLead from '@/components/common/FirstSignInPopUp/PopupStepBecomeSubmissionLead';
import PopupStepTutorial from '@/components/common/FirstSignInPopUp/PopupStepTutorial';
// step content components
import WelcomeScreen from '@/components/common/FirstSignInPopUp/WelcomeScreen';
import { TutorialsStepDataType, dynamicTutorialsData } from '@/components/common/FirstSignInPopUp/config';
import { AuthUserType, ReducedRole } from '@/types/user';
// utils
import { setUserAlreadySignedInInCookie } from '@/utils/cookie-helper';
// routes
import { Routes } from '@/utils/urls';
// mui
import { Dialog, DialogContent } from '@mui/material';

type ExtendedUserType = AuthUserType & {
  name: string;
};

export interface FirstSignInPopUpProps {
  open: boolean;
  onClose: () => void;
  isAuthorityUser: boolean;
  user?: ExtendedUserType;
  userType: ReducedRole;
}

export default function FirstSignInPopUp(props: FirstSignInPopUpProps) {
  const { onClose, open, isAuthorityUser, user, userType } = props;
  const router = useRouter();
  const [
    activeStep,
    setActiveStep,
  ] = useState(0);
  const tutorialStepsData: TutorialsStepDataType[] = dynamicTutorialsData[userType]?.tutorials ?? [];
  const becomeSubmissionLeadStepNumber = tutorialStepsData.length + 1;

  const goStepBack = () => setActiveStep(activeStep - 1);
  const goToNextStep = (step: number) =>
    isAuthorityUser && activeStep + step === becomeSubmissionLeadStepNumber
      ? goToHomepage()
      : setActiveStep(activeStep + step);

  const goToHomepage = () => {
    setUserAlreadySignedInInCookie();
    onClose();
  };
  const goToTeamsPage = async () => {
    setUserAlreadySignedInInCookie();
    await router.push(Routes.Organisation.Users);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          padding: theme => theme.spacing(4),
          background: theme => theme.palette.common.white,
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        {activeStep === 0 && (
          <WelcomeScreen
            userName={user?.name}
            onSkip={() => {
              goToNextStep(tutorialStepsData.length + 1);
            }}
            onNext={() => {
              goToNextStep(1);
            }}
          />
        )}
        {activeStep > 0 &&
          activeStep < becomeSubmissionLeadStepNumber &&
          tutorialStepsData.map((tutorialStepData, index) => (
            <PopupStepTutorial
              totalStepNumber={tutorialStepsData.length}
              key={'tutorial-' + JSON.stringify(tutorialStepData.title)}
              imageSrc={tutorialStepData.imageSrc}
              title={tutorialStepData.title}
              desc={tutorialStepData.desc}
              activeStepIndex={activeStep - 1}
              isActive={index + 1 === activeStep}
              onBack={goStepBack}
              onNext={() => {
                goToNextStep(1);
              }}
            />
          ))}
        {activeStep === becomeSubmissionLeadStepNumber && (
          <PopupStepBecomeSubmissionLead
            goToHomepage={goToHomepage}
            goToTeamsPage={goToTeamsPage}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
