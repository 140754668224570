import { useTranslation } from 'next-i18next';
import { BalanceOutlined, InsertChartOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

//Let's define a common header and a common Layout between all of it's possibilities to keep the code DRY
type Props = {
  handleClickAddOrgsButton?: () => void;
  status?: string;
  sampleName?: string;
};
export const CardHeader = ({ handleClickAddOrgsButton, status, sampleName }: Props) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.authority.distributionAndResponseRateCard.header',
  });
  const isActiveOrClosed = status === 'active' || status === 'closed';
  let headerText = t('TDLHeader');

  if (isActiveOrClosed) {
    headerText = t('sampleHeader');
    if (sampleName) {
      // Check if "sample" is not included in sampleName. If not, we include sample in the title to know this insights are related to a sample, not a TDL
      const formattedSampleName = sampleName.toLowerCase().includes('sample') ? sampleName : `${sampleName} sample`;
      headerText += ` - ${formattedSampleName}`;
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
      >
        {isActiveOrClosed ? <InsertChartOutlined /> : <BalanceOutlined />}
        <Typography
          variant="subtitle1"
          sx={{ marginLeft: 1 }}
        >
          {headerText}
        </Typography>
      </Box>
      {handleClickAddOrgsButton && (
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleClickAddOrgsButton}
        >
          {t('breakdown')}
        </Button>
      )}
    </Box>
  );
};
