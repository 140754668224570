import { Box, Icon, Stack, Typography } from '@mui/material';
import { SharedBoxProps } from './types';

export const SharedBox = ({
  IconComponent,
  label,
  description,
  iconColor,
  circleBackgroundColor,
  cta,
}: SharedBoxProps) => {
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      onClick={cta?.onClick}
      sx={{
        '&:hover': {
          cursor: cta ? 'pointer' : '',
          '& .last-typography': {
            color: theme => theme.palette.primary.main,
          },
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        padding={0.5}
        sx={{ borderRadius: '50px', bgcolor: circleBackgroundColor }}
      >
        <Icon sx={{ color: iconColor }}>{IconComponent}</Icon>
      </Box>
      <Stack
        textAlign="left"
        sx={{ ml: 1 }}
        justifyContent="space-between"
        direction="column"
        height="100%"
      >
        <Stack>
          <Typography variant="caption">{label}</Typography>
          <Typography
            variant="h4"
            component="p"
          >
            {description}
          </Typography>
        </Stack>
        {cta && (
          <Typography
            variant="caption"
            className="last-typography"
            noWrap
            color={theme => theme.palette.text.disabled}
          >
            {cta.text}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
