import { ResourceKey } from 'i18next';
import { ReducedRole } from '@/types/user';

export type TutorialsStepDataType = {
  imageSrc: string;
  title: ResourceKey;
  desc: ResourceKey;
};

export type DynamicTutorialRoleDataType = {
  role?: ReducedRole;
  tutorials?: TutorialsStepDataType[];
};

type DynamicTutorialsDataType = {
  [key in ReducedRole]?: DynamicTutorialRoleDataType;
};

export const dynamicTutorialsData: DynamicTutorialsDataType = {
  [ReducedRole.DISCLOSER_PREFIX]: {
    role: ReducedRole.DISCLOSER_PREFIX,
    tutorials: [
      {
        imageSrc: '/images/firstTimeSignInDiscloser1.png',
        title: 'popups.discloser.first.title',
        desc: 'popups.discloser.first.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInDiscloser2.png',
        title: 'popups.discloser.second.title',
        desc: 'popups.discloser.second.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInDiscloser3.png',
        title: 'popups.discloser.third.title',
        desc: 'popups.discloser.third.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInDiscloser4.png',
        title: 'popups.discloser.fourth.title',
        desc: 'popups.discloser.fourth.desc',
      },
    ],
  },
  [ReducedRole.AUTHORITY_PREFIX]: {
    role: ReducedRole.AUTHORITY_PREFIX,
    tutorials: [
      {
        imageSrc: '/images/firstTimeSignInAuthority1.png',
        title: 'popups.authority.first.title',
        desc: 'popups.authority.first.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInAuthority2.png',
        title: 'popups.authority.second.title',
        desc: 'popups.authority.second.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInAuthority3.png',
        title: 'popups.authority.third.title',
        desc: 'popups.authority.third.desc',
      },
      {
        imageSrc: '/images/firstTimeSignInAuthority4.png',
        title: 'popups.authority.fourth.title',
        desc: 'popups.authority.fourth.desc',
      },
    ],
  },
  [ReducedRole.CDP_ADMIN]: {},
};
