import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Routes } from '@/utils/urls';

export const useIsOnAdmin = (): boolean => {
  const router = useRouter();
  const { data: session } = useSession();

  const isInAdminPath = router.asPath.includes(Routes.Admin.Root);

  return !isInAdminPath && session?.user?.isCDPAdmin && !session?.user?.isImpersonation;
};
