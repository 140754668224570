import { useTranslation } from 'next-i18next';
import { IconTitle } from '@/components/discloser/Dashboard/IconTitle';
import { commonConfig } from '@config';
import { NewspaperOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { CampaignCard } from './CampaignCard';

export const LatestCampaigns = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.latestCampaignsTile' });
  return (
    <Stack
      bgcolor="background.default"
      p={2}
      height="100%"
      alignItems="flex-start"
    >
      <Stack
        direction="row"
        alignContent="space-between"
        width="100%"
      >
        <IconTitle
          icon={<NewspaperOutlined />}
          label={t('title')}
        />
      </Stack>
      <Stack
        mt={1}
        mb={1}
        padding={2}
        gap={1}
      >
        <Typography
          variant="h6"
          component="h1"
        >
          {t('subtitle')}
        </Typography>
        <Typography
          variant="body1"
          component="p"
        >
          {t('description')}
        </Typography>
      </Stack>
      <Stack gap={1}>
        <CampaignCard
          title={t('campaigns.1.title')}
          description={t('campaigns.1.description')}
          linkUrl={commonConfig.campaignLinks.campaign1}
          imageUrl="/images/campaign1.png"
        />
      </Stack>
    </Stack>
  );
};
