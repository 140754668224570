import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { openLinkInNewTab } from '@/utils/url-helper';
import { commonConfig } from '@config';
import { EmailOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

export const EmailTemplates = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.supportTile' });

  const onEmailTemplatesClick = useCallback(() => {
    openLinkInNewTab(commonConfig.emailTemplatesGuidanceLink);
  }, []);

  return (
    <Stack
      display="flex"
      height="100%"
      bgcolor="background.default"
      p={2}
      gap={2}
      onClick={onEmailTemplatesClick}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={1}
      >
        <EmailOutlined />
        <Typography
          variant="subtitle1"
          component="p"
        >
          {t('emailTemplatesTitle')}
        </Typography>
      </Stack>
      <Typography variant="body2">{t('emailTemplatesSubtitle')}</Typography>
    </Stack>
  );
};
