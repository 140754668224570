import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { openLinkInNewTab } from '@/utils/url-helper';
import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';

type Props = {
  title: string;
  description: string;
  linkUrl: string;
  imageUrl: string;
};

export const CampaignCard = ({ title, description, linkUrl, imageUrl }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.latestCampaignsTile' });
  return (
    <Stack>
      <Stack
        direction="row"
        padding={2}
        gap={2}
      >
        <Box sx={{ minWidth: 207 }}>
          <Image
            src={imageUrl}
            width={0}
            height={0}
            sizes="100w"
            style={{ width: '100%', height: '100%', borderRadius: 8 }}
            alt={title}
            priority
          />
        </Box>
        <Stack gap={1}>
          <Typography
            variant="body1"
            component="p"
            fontWeight={700}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            component="p"
          >
            {description}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            width="100%"
          >
            <Button
              onClick={() => openLinkInNewTab(linkUrl)}
              size="small"
              color="primary"
              variant="text"
              startIcon={<ChevronRight />}
            >
              {t('viewCampaignCTA')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
