import { Stack } from '@mui/material';

type Props = {
  header: React.ReactNode;
  children: React.ReactNode;
};
export const CardLayout = ({ header, children }: Props) => {
  return (
    <Stack
      p={2}
      bgcolor={theme => theme.palette.background.default}
      height="100%"
    >
      {header}
      <Stack
        flexGrow={1}
        alignItems="center"
        alignSelf="stretch"
        px={1}
        py={2}
        gap={4}
      >
        {children}
      </Stack>
    </Stack>
  );
};
