import router from 'next/router';
import { Routes } from '@/utils/urls';
import { HomeWorkOutlined, ListAltOutlined, MarkUnreadChatAltOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { SharedBox } from './SharedBox';
import { ListDetailsProps } from './types';

export const ListDetails = ({
  status,
  submittedResponseRate,
  organisationCount,
  discloserListId,
  setResponseStatusFilter,
}: ListDetailsProps) => {
  const theme = useTheme();

  const handleView = async (discloserListId: string, status = '') => {
    await setResponseStatusFilter(status);
    router.push({
      pathname: Routes.Authority.DiscloserList,
      query: {
        discloserListId,
        ...(status && { filter: status }),
      },
    });
  };

  switch (status) {
    case 'active': {
      return (
        <>
          <SharedBox
            IconComponent={<ListAltOutlined />}
            label="List Status"
            description="Active"
            iconColor={theme.palette.success.main}
            circleBackgroundColor="#E2F9D0" // TODO: [wnguyen] fix when design system is finalised
            cta={{
              text: 'See progress',
              onClick: () => handleView(discloserListId),
            }}
          />
          <SharedBox
            IconComponent={<HomeWorkOutlined />}
            label="Organisation count"
            description={organisationCount.toString()}
            cta={{
              text: 'View Organisations',
              onClick: () => handleView(discloserListId),
            }}
          />
          <SharedBox
            IconComponent={<MarkUnreadChatAltOutlined />}
            label="Submitted Response Rate"
            description={submittedResponseRate ? `${submittedResponseRate}%` : `0%`}
            cta={{
              text: 'View responders',
              onClick: () => handleView(discloserListId, 'Submitted'),
            }}
          />
        </>
      );
    }
    case 'pending': {
      return (
        <>
          <SharedBox
            IconComponent={<ListAltOutlined />}
            label="List Status"
            description="Pending"
            iconColor={theme.palette.warning.main}
            circleBackgroundColor="#FFEBCD" // TODO: [wnguyen] fix when design system is finalised
            cta={{
              text: 'See progress',
              onClick: () => handleView(discloserListId),
            }}
          />
          <SharedBox
            IconComponent={<HomeWorkOutlined />}
            label="Organisation count"
            description={organisationCount.toString()}
            cta={{
              text: 'View Organisations',
              onClick: () => handleView(discloserListId),
            }}
          />
        </>
      );
    }
    case 'closed': {
      return (
        <>
          <SharedBox
            IconComponent={<ListAltOutlined />}
            label="List Status"
            description="Closed"
            iconColor={theme.palette.grey[700]}
            circleBackgroundColor={theme.palette.grey[100]}
            cta={{
              text: 'See progress',
              onClick: () => handleView(discloserListId),
            }}
          />
          <SharedBox
            IconComponent={<HomeWorkOutlined />}
            label="Organisation count"
            description={organisationCount.toString()}
            cta={{
              text: 'View Organisations',
              onClick: () => handleView(discloserListId),
            }}
          />
          <SharedBox
            IconComponent={<MarkUnreadChatAltOutlined />}
            label="Submitted Response Rate"
            description={submittedResponseRate ? `${submittedResponseRate}%` : `0%`}
            cta={{
              text: 'View Responders',
              onClick: () => handleView(discloserListId, 'Submitted'),
            }}
          />
        </>
      );
    }
    default:
      throw new Error('Unknown disclosure list status');
  }
};
