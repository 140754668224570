import { create } from 'zustand';
import { AuthorityHubSlice, ManageSlice } from '@/types/discloser';
import { createAuthorityHubSlice } from './authorityHubSlice';
import { createManageSlice } from './manageSlice';

const useDiscloserStore = create<ManageSlice & AuthorityHubSlice>()((...a) => ({
  ...createManageSlice(...a),
  ...createAuthorityHubSlice(...a),
  actions: {
    ...createManageSlice(...a).actions,
    ...createAuthorityHubSlice(...a).actions,
  },
}));

export const useStore = () => useDiscloserStore(state => state);

export const useStatusFilter = () => useDiscloserStore(state => state.statusFilter);

export const useDiscloserActions = () => useDiscloserStore(state => state.actions);
