import { Grid, LinearProgress, Typography } from '@mui/material';

//Active & Closed state
type ProgressRowProps = {
  label: string;
  percentage: number;
  icon: JSX.Element;
};

export const ProgressRow = ({ label, percentage, icon }: ProgressRowProps) => {
  return (
    <Grid
      container
      width="100%"
      alignItems="center"
    >
      <Grid
        item
        xs={1}
      >
        {icon}
      </Grid>
      <Grid
        item
        xs={10}
      >
        <Typography variant="body2">{label}</Typography>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={percentage}
          sx={{ mr: 2 }}
        />
      </Grid>
      <Grid
        item
        xs={1}
      >
        <Typography variant="subtitle2">{percentage}%</Typography>
      </Grid>
    </Grid>
  );
};
