import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { GetServerSideProps } from 'next/types';
import { useEffect, useState } from 'react';
import FirstSignInPopUp from '@/components/common/FirstSignInPopUp/FirstSignInPopUp';
import Loading from '@/components/common/loading/Loading';
import { AuthorityHomePage } from '@/components/home/Authority/AuthorityHomePage';
import { GET_MY_ORG_DETAILS } from '@/graphql/disclosure/queries';
import MainLayout from '@/layouts/main/MainLayout';
import { GetMyOrganisationDetailsQuery } from '@/lib/discloser/__generated__/graphql';
import DiscloserHomePage from '@/pages/home/discloser';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { ReducedRole } from '@/types/user';
import { disclosureClient } from '@/utils/apollo-client';
import { getValueOfIsUserAlreadySignedInCookie } from '@/utils/cookie-helper';
import { onApolloError } from '@/utils/errorFormat';
import { getServerSideTranslations } from '@/utils/i18n';
import { Routes } from '@/utils/urls';
import { useLazyQuery } from '@apollo/client';

const Page = () => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const { role } = useSettings();
  const userType = role as unknown as ReducedRole;

  const [
    getMyOrganisationDetails,
    { data: orgData },
  ] = useLazyQuery<GetMyOrganisationDetailsQuery>(GET_MY_ORG_DETAILS, {
    client: disclosureClient,
    onError: onApolloError,
  });

  const authorityTypes = orgData?.getMyOrganisationDetails?.organisationAuthorityTypes?.map(authorityType => ({
    id: authorityType.authorityType.authorityTypeId,
    name: authorityType.authorityType.name,
  }));

  useEffect(() => {
    if ((session && userType !== ReducedRole.UNKNOWN) || (session?.user.isCDPAdmin && session.user.isImpersonation)) {
      getMyOrganisationDetails();
    }
  }, [
    session?.user?.extension_OrganisationId,
  ]);

  // Update the locale if the user has a different language preference set
  //useEffect(() => {
  //if (session?.user?.extension_PortalLanguage !== 'en') {
  //  router.push({ pathname, query }, asPath, { locale: session?.user?.extension_PortalLanguage });
  //}
  //}, [
  // session?.user?.extension_PortalLanguage,
  //]);

  const [
    isFirstSignInPopupOpen,
    setIsFirstSignInPopupOpen,
  ] = useState<boolean>(!getValueOfIsUserAlreadySignedInCookie());

  if (session === null && status === 'loading') return <Loading />;

  /**
   * If user signs first time, there will be no cookie for "firstTimeSignInCookieName", in this case we want display extra popup for the user.
   */
  if (isFirstSignInPopupOpen && !session?.user?.isCDPAdmin) {
    return (
      <FirstSignInPopUp
        open={isFirstSignInPopupOpen}
        onClose={() => {
          setIsFirstSignInPopupOpen(false);
        }}
        isAuthorityUser={userType === ReducedRole.AUTHORITY_PREFIX}
        user={session?.user}
        userType={userType === ReducedRole.CDP_ADMIN ? ReducedRole.DISCLOSER_PREFIX : userType}
      />
    );
  }

  switch (userType) {
    case ReducedRole.UNKNOWN:
      return <div />;
    case ReducedRole.DISCLOSER_PREFIX:
      return <DiscloserHomePage />;
    case ReducedRole.AUTHORITY_PREFIX:
      return <AuthorityHomePage />;
    case ReducedRole.CDP_ADMIN: {
      if (!session?.user?.extension_OrganisationId) {
        router.push(Routes.Admin.Root);
        return null;
      }
    }
    // eslint-disable-next-line no-fallthrough
    default: {
      return authorityTypes?.length ? <AuthorityHomePage /> : <DiscloserHomePage />;
    }
  }
};

Page.getLayout = (page: React.ReactElement) => <MainLayout>{page}</MainLayout>;

export const getServerSideProps: GetServerSideProps = async context => {
  try {
    return {
      props: {
        ...(await getServerSideTranslations(context.locale, [
          'common',
          'questionnaire',
        ])),
      },
    };
  } catch {
    return { notFound: true };
  }
};

export default Page;
