import { useTranslation } from 'next-i18next';
import { PieChartOutlineOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

export const CDPScore = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.supportTile' });
  return (
    <Stack
      display="flex"
      height="100%"
      bgcolor="background.default"
      p={2}
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={1}
      >
        <PieChartOutlineOutlined />
        <Typography
          variant="subtitle1"
          component="p"
        >
          {t('cdpScoreTitle')}
        </Typography>
      </Stack>
      <Typography
        variant="h3"
        component="p"
      >
        {t('noScore')}
      </Typography>
    </Stack>
  );
};
