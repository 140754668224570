import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { PhaseInformation } from '@/components/common/Timeline/PhaseInformation';
import Loading from '@/components/common/loading/Loading';
import { GET_MY_ORG_DETAILS } from '@/graphql/disclosure/queries';
import { GetMyOrganisationDetailsQuery } from '@/lib/discloser/__generated__/graphql';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { Button, Stack, Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
  membership?: string;
  alerts?: React.ReactNode;
};
export const HomePageLayout = ({ children, membership, alerts }: Props) => {
  const router = useRouter();
  const { t } = useTranslation('common', { keyPrefix: 'common.homePageHeader' });

  const onDetailsClick = () => router.push(Routes.Organisation.Details);

  const { data, loading } = useQuery<GetMyOrganisationDetailsQuery>(GET_MY_ORG_DETAILS, {
    client: disclosureClient,
    onError: onApolloError,
  });

  if (loading) return <Loading isContained />;

  return (
    <Stack gap={2}>
      <Stack>
        <Stack>
          {membership && (
            <Typography
              variant="body1"
              component="p"
            >
              {membership}
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Typography
              variant="h3"
              component="h1"
            >
              {data?.getMyOrganisationDetails?.orgName}
            </Typography>
            <Button
              variant="text"
              onClick={onDetailsClick}
            >
              {t('ctaLabel')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {alerts && <Stack>{alerts}</Stack>}
      <PhaseInformation />
      {children}
    </Stack>
  );
};
