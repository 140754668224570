import { useTranslation } from 'next-i18next';
import { GET_ORGANISATION_ACCOUNT_MANAGER } from '@/graphql/disclosure/queries';
import { GetOrganisationAccountManagerQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { Grid, Skeleton } from '@mui/material';
import { AccountManagement } from './AccountManagement';
import { CDPScore } from './CDPScore';
import { EmailTemplates } from './EmailTemplates';
import { LatestGuidance } from './LatestGuidance';
import { LatestWebinar } from './LatestWebinar';

export const SupportGrid = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });
  const { authorityTypeId } = useStore();

  const { data, loading } = useQuery<GetOrganisationAccountManagerQuery>(GET_ORGANISATION_ACCOUNT_MANAGER, {
    variables: { authorityTypeId },
    client: disclosureClient,
    onError: onApolloError,
  });

  const accData = data?.getOrganisationAccountManager;
  const gridSize = !accData && !loading ? 12 / 4 : 12 / 5;

  return (
    <>
      <Grid
        item
        md={gridSize}
      >
        <LatestWebinar />
      </Grid>

      <Grid
        item
        md={gridSize}
      >
        <LatestGuidance />
      </Grid>

      <Grid
        item
        md={gridSize}
      >
        <EmailTemplates />
      </Grid>

      <Grid
        item
        md={gridSize}
      >
        <CDPScore />
      </Grid>

      {loading ? (
        <Grid
          item
          md={gridSize}
        >
          <Skeleton aria-label={t('loading')} />
        </Grid>
      ) : (
        accData && (
          <Grid
            item
            md={gridSize}
          >
            <AccountManagement
              name={`${accData.firstName} ${accData.lastName}`}
              email={accData.emailAddress}
            />
          </Grid>
        )
      )}
    </>
  );
};
