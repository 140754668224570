export const AUTHORITY_TYPE_PRIORITY_LIST = [
  'Supply Chain',
  'Banks Program',
  'Private Markets',
  'Capital Markets Signatory',
  'CSTAR',
  'Corporate',
  'Net Zero Asset Managers',
  'CDP Cities ',
  'CDP States and Regions ',
  'Sustainable Finance',
];
