import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ListDatesProps } from './types';

export const ListDates = ({ year, submitDueDate, deadlineToDisclose }: ListDatesProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.authority.new.kpiTile' });

  return (
    <Box
      display="flex"
      padding={2}
      alignItems="center"
      gap={4}
      bgcolor={theme => theme.palette.background.default}
    >
      <Box>
        <Typography
          noWrap
          variant="caption"
        >
          {t('discloserCycleYearTitle')}
        </Typography>
        <Typography
          variant="h6"
          component={'p'}
        >
          {year}
        </Typography>
      </Box>

      <Box>
        <Typography
          noWrap
          variant="caption"
        >
          {t('lastSubmissionDeadlineTitle')}
        </Typography>
        <Typography
          variant="h6"
          component={'p'}
        >
          {submitDueDate}
        </Typography>
      </Box>

      <Box>
        <Typography
          noWrap
          variant="caption"
        >
          {t('deadlineToDiscloseTitle')}
        </Typography>
        <Typography
          variant="h6"
          component={'p'}
        >
          {deadlineToDisclose}
        </Typography>
      </Box>
    </Box>
  );
};
