/**
 * This cookie helper is currently only used for identifying that the user have the cookie for already signed in or not.
 */

// the cookie name for already signed-in user boolean value
export const firstTimeSignInCookieName = 'isUserAlreadySignedIn';

export const getValueOfIsUserAlreadySignedInCookie = () => {
  const wholeCookie = '; ' + document.cookie;
  const parts = wholeCookie.split('; ' + firstTimeSignInCookieName + '=');

  let value;
  if (parts.length == 2) {
    value = parts.pop()?.split(';').shift();
  }
  // if the cookie not exist, then we want to return false.
  return value === 'true';
};

export const setUserAlreadySignedInInCookie = () => {
  const date = new Date();
  // Cookie expires in 1 year
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie = firstTimeSignInCookieName + '=true; expires=' + date.toUTCString() + '; path=/';
};
