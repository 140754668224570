import { DistributionAndResponseRateCard } from '@/components/discloser/Dashboard/DistributionAndResponseRateCard';
import { AlertCantSubmitList } from '@/components/home/Authority/Alerts/AlertCantSubmitList';
import { formatDate } from '@/utils/date';
import { Grid } from '@mui/material';
import { DataAndInsightsTile } from '../../DataAndInsightsTile/DataAndInsightsTile';
import { KPICard, List } from '../../KPICard';
import { LatestCampaigns, SuggestedKeyActions, SupportGrid } from '../HomeTiles';
import { CurrentList } from '../types';
import { HomePageLayout } from './HomePageLayout';

type Props = { data: CurrentList };
export const CurrentListView = ({ data }: Props) => {
  const {
    currentListYear,
    currentListDeadline,
    currentListSubmitDueDate,
    invitedOrganisationsCount,
    submittedResponseRate,
    status,
    totalOrganisationCount,
    disclosureCycleId,
    discloserListId,
  } = data;

  const kpiCardData: List = {
    _tag: 'List',
    year: currentListYear,
    submitDueDate: formatDate(currentListSubmitDueDate, 'MMM d'),
    deadlineToDisclose: formatDate(currentListDeadline, 'MMM d'),
    discloserListId: discloserListId,
    status: status,
    organisationCount: invitedOrganisationsCount,
    submittedResponseRate: submittedResponseRate,
  };

  return (
    <HomePageLayout alerts={<AlertCantSubmitList viewType={data._tag} />}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
        >
          <KPICard data={kpiCardData} />
        </Grid>
        <Grid
          item
          md={6}
        >
          <DistributionAndResponseRateCard
            status={status}
            organisationCount={totalOrganisationCount}
            disclosureCycleId={disclosureCycleId}
            discloserListId={discloserListId}
          />
        </Grid>
        <Grid
          item
          md={6}
        >
          <SuggestedKeyActions discloserListId={discloserListId} />
        </Grid>
        <SupportGrid />
        <Grid
          item
          md={6}
        >
          <DataAndInsightsTile />
        </Grid>
        <Grid
          item
          md={6}
        >
          <LatestCampaigns />
        </Grid>
      </Grid>
    </HomePageLayout>
  );
};
