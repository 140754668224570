import { StateCreator } from 'zustand';
import { DiscloserListModel } from '@/lib/discloser/__generated__/graphql';
import { ListFilter, ManageSlice } from '@/types/discloser';

export const createManageSlice: StateCreator<ManageSlice> = set => ({
  statusFilter: 'all',
  discloserLists: [],
  filteredDiscloserLists: [],

  actions: {
    setStatusFilter: async (params: ListFilter) =>
      set(() => ({
        statusFilter: params,
      })),
    setDiscloserLists: async (disclosers: DiscloserListModel[]) =>
      set(() => ({
        discloserLists: disclosers,
      })),
    setFilteredDiscloserLists: async (disclosers: DiscloserListModel[]) =>
      set(() => ({
        filteredDiscloserLists: disclosers,
      })),
  },
});
