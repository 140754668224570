import { DistributionAndResponseRateCard } from '@/components/discloser/Dashboard/DistributionAndResponseRateCard';
import { formatDate } from '@/utils/date';
import { Grid } from '@mui/material';
import { DataAndInsightsTile } from '../../DataAndInsightsTile/DataAndInsightsTile';
import { KPICard, List } from '../../KPICard';
import { LatestCampaigns, SuggestedKeyActions, SupportGrid } from '../HomeTiles';
import { PreviousList } from '../types';
import { HomePageLayout } from './HomePageLayout';

type Props = { data: PreviousList };
export const PreviousListView = ({ data }: Props) => {
  const {
    prevListYear,
    prevListDeadline,
    prevListSubmitDueDate,
    invitedOrganisationsCount,
    submittedResponseRate,
    status,
    totalOrganisationCount,
    disclosureCycleId,
    discloserListId,
  } = data;

  const kpiCardData: List = {
    _tag: 'List',
    submitDueDate: formatDate(prevListSubmitDueDate, 'MMM d'),
    deadlineToDisclose: formatDate(prevListDeadline, 'MMM d'),
    organisationCount: invitedOrganisationsCount,
    year: prevListYear,
    submittedResponseRate,
    discloserListId,
    status,
  };

  return (
    <HomePageLayout>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
        >
          <KPICard data={kpiCardData} />
        </Grid>
        <Grid
          item
          md={6}
        >
          <DistributionAndResponseRateCard
            status={status}
            organisationCount={totalOrganisationCount}
            disclosureCycleId={disclosureCycleId}
            discloserListId={discloserListId}
          />
        </Grid>
        <Grid
          item
          md={6}
        >
          <SuggestedKeyActions discloserListId={discloserListId} />
        </Grid>
        <SupportGrid />
        <Grid
          item
          md={6}
        >
          <DataAndInsightsTile />
        </Grid>
        <Grid
          item
          md={6}
        >
          <LatestCampaigns />
        </Grid>
      </Grid>
    </HomePageLayout>
  );
};
